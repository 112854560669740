<template>
  <div>
    <v-card flat>
      <v-card-text id="content-card">
        <v-row align="center" no-gutters style="margin-bottom: 20px">
          <v-col cols="6" sm="6" md="1">
            <h4 class="primaryText--text">Load Sea Port:</h4>
          </v-col>
          <v-col cols="6" sm="6" md="1">
            <v-row align="center">
              {{ item.booking.portOfLoadValue }}
              
            </v-row>
          </v-col>

          <v-col cols="6" sm="6" md="1">
            <h4 class="primaryText--text">
              Target Country: <br />
              Target Region:
            </h4>
          </v-col>
          <v-col cols="6" sm="6" md="1">
            <v-row align="center">

              {{
                finalDestinationCode(item.booking.bookingFinalDestinationCode)
              }}
            </v-row>
            <br />
            <v-row align="center">
              {{ item.booking.targetRegion }}
            </v-row>
          </v-col>

          <v-col cols="6" sm="6" md="1">
            <h4 class="primaryText--text">Port of Entry:</h4>
          </v-col>
          <v-col cols="6" sm="6" md="1">
            <v-row align="center">
              {{ item.booking.finalDestinationValue }}
            </v-row>
          </v-col>

          <v-col cols="6" sm="6" md="1">
            <h4 class="primaryText--text">
              Vessel: <br />
              Voyage:
            </h4>
          </v-col>
          <v-col cols="6" sm="6" md="1">
            <v-row align="center">
              <!-- <v-simple-checkbox color="success" class="mr-1" v-model="item.ppecb.mainCarriageVesselName"></v-simple-checkbox> -->
              <!-- <v-btn
                :key="propertyKey"
                icon
                :color="getCheckColour(item.ppecb.mainCarriageVesselName)"
                @click="changePropertyValue('mainCarriageVesselName')"
                ><v-icon>{{
                  getCheckIcon(item.ppecb.mainCarriageVesselName)
                }}</v-icon></v-btn
              > -->

              {{ item.booking.mainCarriageVesselName }}
            </v-row>
            <br />
            <v-row align="center">
              {{ item.booking.mainCarriageConveyanceNumber }}
            </v-row>
          </v-col>
          <v-col cols="6" sm="6" md="1">
            <h4 class="primaryText--text">Departure Date</h4>
            <h4 class="primaryText--text">Regime Code</h4>
          </v-col>
          <v-col cols="6" sm="6" md="1">
            <v-row align="center">
              {{ formatDate(item.booking.etd) }}
            </v-row>
            <br />
            <v-row align="center">
              {{ item.booking.regimeCode }}
            </v-row>
          </v-col>
        </v-row>
        <v-data-table height="62vh" fixed-header item-key="id" dense hide-default-footer disable-pagination
          :item-class="itemRowBackground" :headers="headers" :items="item.containerPallets" style="cursor: pointer">

          <template v-slot:[`item.action`]="{ item }">
            <v-btn icon @click="editPallet(item)"><v-icon>attach_file</v-icon></v-btn>

          </template>
          <template v-slot:[`item.line`]="{ item , index }">
            <template v-if="breakBulk">
              {{ uniquePallets.indexOf(item.sscc) + 1 }}
            </template>
            <template v-else>
              {{ index+1}}
            </template>
            <!-- <v-chip small color="orange" class="white--text" v-if="!item.titanReady && auditLines.includes(index + 1)">
              {{ index + 1 }}
            </v-chip>
            <span v-else> {{ index + 1 }}</span> -->
          </template>

          <template v-slot:[`item.additional`]="{ item }">
            <v-tooltip top v-if="item.consignmentNote">
              <template v-slot:activator="{ on }">
                <v-btn class="mx-1" outlined v-on="on" small icon @click="viewConsignmentNote(item)">
                  <v-icon small>description</v-icon>
                </v-btn>
              </template>
              <span>Consignment Note</span>
            </v-tooltip>

            <v-tooltip top v-if="item.permitDocument">
              <template v-slot:activator="{ on }">
                <v-btn class="mx-1" outlined v-on="on" small icon @click="viewRemovalPermit(item)">
                  <v-icon small>agriculture</v-icon>
                </v-btn>
              </template>
              <span>Removal Permit: {{ item.permitNo }} expires
                {{ item.permitExpiryDate }}</span>
            </v-tooltip>

            <v-tooltip top v-if="checkMetadata('Titan Ready', item.palletMetadata)">
              <template v-slot:activator="{ on }">
                <v-chip color="grey --lighten-3" small class="mx-1" v-on="on" @click="viewTitan(item)"
                 >
                  <v-icon v-if="verify('Titan Ready', item.palletMetadata)" small left color="black">check</v-icon>
                  <v-icon v-else color="red" left small>close</v-icon>
                  <v-img contain style="width: 35px" rel="preload"
                          src="../../../assets/ppecblogo.png"></v-img>
                </v-chip>
              </template>
              <span>Titan Check:
                {{ metadataValue("Titan Ready", item.palletMetadata) }}</span>
            </v-tooltip>

            <v-tooltip top v-if="checkMetadata('Phytclean Verified', item.palletMetadata)">
              <template v-slot:activator="{ on }">
                <v-chip small class="mx-1" v-on="on" @click="viewPhytoCertificate(item)"
                 >
                  <v-icon v-if="verify('Phytclean Verified', item.palletMetadata)" small left color="black">check</v-icon>
                  <v-icon v-else color="red" left small>close</v-icon>
                  <v-img contain style="width: 35px" rel="preload"
                          src="../../../assets/PhytClean.png"></v-img>
                </v-chip>
              </template>
              <span>Phytclean verification:
                {{
                  metadataValue("Phytclean Verified", item.palletMetadata)
                }}</span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.farm`]="{ item }">
            <span v-if="item.farm && !checkField('farm', item)" :key="loadingKey">{{ item.farm }}</span>
            <span v-else-if="checkField('farm', item)">
              <v-btn :key="loadingKey" small v-if="!item.farm" :loading="loadingField == 'add_farm_' + item.id" text
                color="green" @click="updatePallet('add', 'farm', item)"><v-icon small left>add</v-icon>
                {{ item.containerPalletAddendum.farm }}</v-btn>
              <v-btn small v-else text color="blue" :loading="loadingField == 'update_farm_' + item.id"
                @click="updatePallet('update', 'farm', item)">
                <span style="padding-right: 5px">{{ item.containerPalletAddendum.farm }}
                </span>
                <v-icon small>swap_horiz</v-icon>
                <span style="padding-left: 5px; color: var(--v-primaryText-base)">{{ item.farm }}</span>
              </v-btn>
            </span>
          </template>

          <template v-slot:[`item.packHouse`]="{ item }">
            <span v-if="item.packHouse && !checkField('packHouse', item)" :key="loadingKey">{{ item.packHouse }}</span>
            <span v-else-if="checkField('packHouse', item)">
              <v-btn :key="loadingKey" small v-if="!item.packHouse" :loading="loadingField == 'add_packHouse_' + item.id"
                text color="green" @click="updatePallet('add', 'packHouse', item)"><v-icon small left>add</v-icon>
                {{ item.containerPalletAddendum.packHouse }}</v-btn>
              <v-btn small v-else text color="blue" :loading="loadingField == 'update_packHouse_' + item.id"
                @click="updatePallet('update', 'packHouse', item)">
                <span style="padding-right: 5px">{{ item.containerPalletAddendum.packHouse }}
                </span>
                <v-icon small>swap_horiz</v-icon>
                <span style="padding-left: 5px; color: var(--v-primaryText-base)">{{ item.packHouse }}</span>
              </v-btn>
            </span>
          </template>

          <template v-slot:[`item.orchard`]="{ item }">
            <span v-if="item.orchard && !checkField('orchard', item)" :key="loadingKey">{{ item.orchard }}</span>
            <span v-else-if="checkField('orchard', item)">
              <v-btn :key="loadingKey" small v-if="!item.orchard" :loading="loadingField == 'add_orchard_' + item.id" text
                color="green" @click="updatePallet('add', 'orchard', item)"><v-icon small left>add</v-icon>
                {{ item.containerPalletAddendum.orchard }}</v-btn>
              <v-btn small v-else text color="blue" :loading="loadingField == 'update_orchard_' + item.id"
                @click="updatePallet('update', 'orchard', item)">
                <span style="padding-right: 5px">{{ item.containerPalletAddendum.orchard }}
                </span>
                <v-icon small>swap_horiz</v-icon>
                <span style="padding-left: 5px; color: var(--v-primaryText-base)">{{ item.orchard }}</span>
              </v-btn>
            </span>
          </template>

          <template v-slot:[`item.gradeClassCode`]="{ item }">
            <span v-if="item.gradeClassCode && !checkField('gradeClassCode', item)" :key="loadingKey">{{
              item.gradeClassCode }}</span>
            <span v-else-if="checkField('gradeClassCode', item)">
              <v-btn :key="loadingKey" small v-if="!item.gradeClassCode"
                :loading="loadingField == 'add_gradeClassCode_' + item.id" text color="green"
                @click="updatePallet('add', 'gradeClassCode', item)"><v-icon small left>add</v-icon>
                {{ item.containerPalletAddendum.gradeClassCode }}</v-btn>
              <v-btn small v-else text color="blue" :loading="loadingField == 'update_gradeClassCode_' + item.id"
                @click="updatePallet('update', 'gradeClassCode', item)">
                <span style="padding-right: 5px">{{ item.containerPalletAddendum.gradeClassCode }}
                </span>
                <v-icon small>swap_horiz</v-icon>
                <span style="padding-left: 5px; color: var(--v-primaryText-base)">{{ item.gradeClassCode }}</span>
              </v-btn>
            </span>
          </template>

          <template v-slot:[`item.nettWeight`]="{ item }">
            <span v-if="item.nettWeight && !checkField('nettWeight', item)" :key="loadingKey">{{ item.nettWeight }}</span>
            <span v-else-if="checkField('nettWeight', item)">
              <v-btn :key="loadingKey" small v-if="!item.nettWeight"
                :loading="loadingField == 'add_nettWeight_' + item.id" text color="green"
                @click="updatePallet('add', 'nettWeight', item)"><v-icon small left>add</v-icon>
                {{ item.containerPalletAddendum.nettWeight }}</v-btn>
              <v-btn small v-else text color="blue" :loading="loadingField == 'update_nettWeight_' + item.id"
                @click="updatePallet('update', 'nettWeight', item)">
                <span style="padding-right: 5px">{{ item.containerPalletAddendum.nettWeight }}
                </span>
                <v-icon small>swap_horiz</v-icon>
                <span style="padding-left: 5px; color: var(--v-primaryText-base)">{{ item.nettWeight }}</span>
              </v-btn>
            </span>
          </template>

          <template v-slot:[`item.inspectionDate`]="{ item }">
            <span v-if="item.inspectionDate && !checkField('inspectionDate', item)" :key="loadingKey">{{
              item.inspectionDate }}</span>
            <span v-else-if="checkField('inspectionDate', item)">
              <v-btn :key="loadingKey" small v-if="!item.inspectionDate"
                :loading="loadingField == 'add_inspectionDate_' + item.id" text color="green"
                @click="updatePallet('add', 'inspectionDate', item)"><v-icon small left>add</v-icon>
                {{ item.containerPalletAddendum.inspectionDate }}</v-btn>
              <v-btn small v-else text color="blue" :loading="loadingField == 'update_inspectionDate_' + item.id"
                @click="updatePallet('update', 'inspectionDate', item)">
                <span style="padding-right: 5px">{{ item.containerPalletAddendum.inspectionDate }}
                </span>
                <v-icon small>swap_horiz</v-icon>
                <span style="padding-left: 5px; color: var(--v-primaryText-base)">{{ item.inspectionDate }}</span>
              </v-btn>
            </span>
          </template>

          <template v-slot:[`item.stuffDate`]="{ item }">
            <span v-if="item.stuffDate && !checkField('stuffDate', item)" :key="loadingKey">{{ item.stuffDate }}</span>
            <span v-else-if="checkField('stuffDate', item)">
              <v-btn :key="loadingKey" small v-if="!item.stuffDate" :loading="loadingField == 'add_stuffDate_' + item.id"
                text color="green" @click="updatePallet('add', 'stuffDate', item)"><v-icon small left>add</v-icon>
                {{ item.containerPalletAddendum.stuffDate }}</v-btn>
              <v-btn small v-else text color="blue" :loading="loadingField == 'update_stuffDate_' + item.id"
                @click="updatePallet('update', 'stuffDate', item)">
                <span style="padding-right: 5px">{{ item.containerPalletAddendum.stuffDate }}
                </span>
                <v-icon small>swap_horiz</v-icon>
                <span style="padding-left: 5px; color: var(--v-primaryText-base)">{{ item.stuffDate }}</span>
              </v-btn>
            </span>
          </template>

          <template v-slot:[`item.inspectionManifestNo`]="{ item }">
            <span v-if="item.inspectionManifestNo &&
              !checkField('inspectionManifestNo', item)
              " :key="loadingKey">{{ item.inspectionManifestNo }}</span>
            <span v-else-if="checkField('inspectionManifestNo', item)">
              <v-btn :key="loadingKey" small v-if="!item.inspectionManifestNo"
                :loading="loadingField == 'add_inspectionManifestNo_' + item.id" text color="green"
                @click="updatePallet('add', 'inspectionManifestNo', item)"><v-icon small left>add</v-icon>
                {{ item.containerPalletAddendum.inspectionManifestNo }}</v-btn>
              <v-btn small v-else text color="blue" :loading="loadingField == 'update_inspectionManifestNo_' + item.id
                " @click="updatePallet('update', 'inspectionManifestNo', item)">
                <span style="padding-right: 5px">{{ item.containerPalletAddendum.inspectionManifestNo }}
                </span>
                <v-icon small>swap_horiz</v-icon>
                <span style="padding-left: 5px; color: var(--v-primaryText-base)">{{ item.inspectionManifestNo }}</span>
              </v-btn>
            </span>
          </template>

          <template v-slot:[`item.inspectionPoint`]="{ item }">
            <span v-if="item.inspectionPoint && !checkField('inspectionPoint', item)
              " :key="loadingKey">{{ item.inspectionPoint }}</span>
            <span v-else-if="checkField('inspectionPoint', item)">
              <v-btn :key="loadingKey" small v-if="!item.inspectionPoint"
                :loading="loadingField == 'add_inspectionPoint_' + item.id" text color="green"
                @click="updatePallet('add', 'inspectionPoint', item)"><v-icon small left>add</v-icon>
                {{ item.containerPalletAddendum.inspectionPoint }}</v-btn>
              <v-btn small v-else text color="blue" :loading="loadingField == 'update_inspectionPoint_' + item.id"
                @click="updatePallet('update', 'inspectionPoint', item)">
                <span style="padding-right: 5px">{{ item.containerPalletAddendum.inspectionPoint }}
                </span>
                <v-icon small>swap_horiz</v-icon>
                <span style="padding-left: 5px; color: var(--v-primaryText-base)">{{ item.inspectionPoint }}</span>
              </v-btn>
            </span>
          </template>
        </v-data-table>
        <v-row justify="end" class="mt-2">
          <v-col cols="12" md="4">
          </v-col>
          <v-col cols="12" md="2">
            <v-row align="center">
              Total Cartons:
              <u class="ml-2"><b>{{ formatNumber(sumCartons) }}</b></u>
            </v-row>

            <v-row align="center">
              Gross Weight:
              <u class="ml-2"><b>{{ formatNumber(sumGrossWeight.toFixed(2)) }} Kg</b></u>
            </v-row>
            <v-row align="center">
              Nett Weight:
              <u class="ml-2"><b>{{ formatNumber(sumNettWeight.toFixed(2)) }} Kg</b></u>
            </v-row>
          </v-col>
          <v-col cols="12" md="2">
            <v-row align="center">
              Total Pallets:

              <u class="ml-2"><b> {{ numberOfPallets }}</b></u>
            </v-row>
          </v-col>

        </v-row>
      </v-card-text>
    </v-card>

    <v-dialog v-model="modal" :fullscreen="$vuetify.breakpoint.mobile || fullscreen" width="1200px">
      <v-card flat>
        <v-toolbar flat>
          <v-toolbar-title style="font-size: 16px">
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="fullscreen = !fullscreen" icon><v-icon v-if="!fullscreen">fullscreen</v-icon><v-icon
              v-else>fullscreen_exit</v-icon></v-btn>
          <v-btn icon :loading="documentItem.loading"
            @click="downloadDocument(documentItem)"><v-icon>download</v-icon></v-btn>
          <v-btn v-if="documentItem.name" text @click="$refs.pdf.print()" icon>
            <v-icon>print</v-icon>
          </v-btn>
          <v-btn icon @click="(modal = false), (documentItem = {})">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <el-container>
            <el-main :key="itemKey" v-loading="documentItem.loadingImage">
              <div style="height: 75vh; overflow-y: auto">
                <div v-if="documentItem.fileType == 'application/pdf'">
                  <pdf ref="pdf" :src="documentItem.file"></pdf>
                </div>

                <v-img v-else-if="documentItem.fileType &&
                  documentItem.fileType.includes('image')
                  " :style="{ transform: `rotate(${rotate}deg)` }" :src="documentItem.file"></v-img>
              </div>
            </el-main>
          </el-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="consignmentNoteModal" :fullscreen="$vuetify.breakpoint.mobile || consignmentNoteFullscreen"
      width="1500px">
      <v-card flat>
        <v-toolbar flat>
          <v-toolbar-title style="font-size: 16px" color="transparent">
            {{ documentItem.sscc }} <span v-if="displayPage">(Detected on page {{ displayPage }})</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn v-if="documentItem.name" icon small @click="rotate += 90">
            <v-icon>rotate_right</v-icon>
          </v-btn>
          <v-btn v-if="documentItem.name" small icon @click="rotate -= 90">
            <v-icon>rotate_left</v-icon>
          </v-btn>
          <v-btn @click="consignmentNoteFullscreen = !consignmentNoteFullscreen" icon><v-icon
              v-if="!consignmentNoteFullscreen">fullscreen</v-icon><v-icon v-else>fullscreen_exit</v-icon></v-btn>
          <!-- <v-btn icon @click="downloadDocument(documentItem)"
            :loading="downloadingFile == documentItem.id"><v-icon>download</v-icon></v-btn>
          <v-btn v-if="documentItem.name" text @click="$refs.pdf[0].print()">
            <v-icon>print</v-icon> 
          </v-btn> -->
          <v-btn icon
            @click="(consignmentNoteModal = false), (documentItem = {}), displayPage = null, consignmentNoteTab = 0">X</v-btn>
        </v-toolbar>
      <v-card-text v-if="documentItem && documentItem.pallet">
          <v-list dense>
            <v-row>
              <v-col cols="12" sm="2">
                <v-list-item style="height: 15px">
                  <v-list-item-content>
                    <v-list-item-title>
                      PUC
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action-text>
                    {{ documentItem.pallet.farm }}
                  </v-list-item-action-text>
                </v-list-item>
                <v-list-item style="height: 15px">
                  <v-list-item-content>
                    <v-list-item-title>
                      PHC
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action-text>
                    {{ documentItem.pallet.packHouse }}
                  </v-list-item-action-text>
                </v-list-item>
                <v-list-item style="height: 15px">
                  <v-list-item-content>
                    <v-list-item-title>
                      Orchard
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action-text>
                    {{ documentItem.pallet.orchard }}
                  </v-list-item-action-text>
                </v-list-item>


              </v-col>
              <v-col cols="12" sm="2">
                <v-list-item style="height: 15px">
                  <v-list-item-content>
                    <v-list-item-title>
                      Production Area
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action-text>
                    {{ documentItem.pallet.productionArea }}
                  </v-list-item-action-text>
                </v-list-item>
                <v-list-item style="height: 15px">
                  <v-list-item-content>
                    <v-list-item-title>
                      Phyto Data
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action-text>
                    {{ documentItem.pallet.phytoReference }}
                  </v-list-item-action-text>
                </v-list-item>

                <v-list-item style="height: 15px">
                  <v-list-item-content>
                    <v-list-item-title>
                      Container No.
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action-text>
                    {{ documentItem.pallet.containerNo }}
                  </v-list-item-action-text>
                </v-list-item>

              </v-col>
              <v-col cols="12" sm="2">
                <v-list-item style="height: 15px">
                  <v-list-item-content>
                    <v-list-item-title>
                      Commodity
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action-text>
                    {{ documentItem.pallet.commodity }}
                  </v-list-item-action-text>
                </v-list-item>
                <v-list-item style="height: 15px">
                  <v-list-item-content>
                    <v-list-item-title>
                      Variety
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action-text>
                    {{ documentItem.pallet.variety }}
                  </v-list-item-action-text>
                </v-list-item>
                <v-list-item style="height: 15px">
                  <v-list-item-content>
                    <v-list-item-title>
                      Flesh Colour
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action-text>
                    {{ documentItem.pallet.fleshColour }}
                  </v-list-item-action-text>
                </v-list-item>

              </v-col>
              <v-col cols="12" sm="2">
                <v-list-item style="height: 15px">
                  <v-list-item-content>
                    <v-list-item-title>
                      Count
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action-text>
                    {{ documentItem.pallet.countSize }}
                  </v-list-item-action-text>
                </v-list-item>
                <v-list-item style="height: 15px">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ documentItem.pallet.packType ?? 'Cartons' }}
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action-text>
                    {{ documentItem.pallet.noCartons }}
                  </v-list-item-action-text>
                </v-list-item>
                <v-list-item style="height: 15px">
                  <v-list-item-content>
                    <v-list-item-title>
                      Grade/Class
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action-text>
                    <span v-if="documentItem.pallet.gradeClassCode.includes(1)">
                      {{ 1 }}
                    </span>
                    <span v-else-if="documentItem.pallet.gradeClassCode.includes(2)">
                      {{ 2 }}
                    </span>
                    <span v-else-if="documentItem.pallet.gradeClassCode.includes('P')">
                      {{ 'P' }}
                      </span>
                  </v-list-item-action-text>
                </v-list-item>

              </v-col>
              <v-col cols="12" sm="2">
                <v-list-item style="height: 15px">
                  <v-list-item-content>
                    <v-list-item-title>
                      Manifest No
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action-text>
                    {{ documentItem.pallet.inspectionManifestNo }}
                  </v-list-item-action-text>
                </v-list-item>
                <v-list-item style="height: 15px">
                  <v-list-item-content>
                    <v-list-item-title>
                      Inspection Date
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action-text>
                    {{ documentItem.pallet.inspectionDate }}
                  </v-list-item-action-text>
                </v-list-item>
                <v-list-item style="height: 15px">
                  <v-list-item-content>
                    <v-list-item-title>
                      Inspection Point
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action-text>
                    {{ documentItem.pallet.inspectionPoint }}
                  </v-list-item-action-text>
                </v-list-item>
              </v-col>

              <v-col cols="12" sm="2">
                <v-list-item style="height: 15px">
                  <v-list-item-content>
                    <v-list-item-title>
                      Nett Weight
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action-text>
                    {{ documentItem.pallet.nettWeight }} KG
                  </v-list-item-action-text>
                </v-list-item>
                <v-list-item style="height: 15px">
                  <v-list-item-content>
                    <v-list-item-title>
                      Gross Weight
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action-text>
                    {{ documentItem.pallet.grossWeight }} KG
                  </v-list-item-action-text>
                </v-list-item>
                <v-list-item style="height: 15px">
                  <v-list-item-content>
                    <v-list-item-title>
                      Stuff Date
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action-text>
                    {{ documentItem.pallet.stuffDate }}
                  </v-list-item-action-text>
                </v-list-item>
              </v-col>


            </v-row>
          </v-list>

          <el-container :key="itemKey" v-loading="documentItem.loadingImage">
            <v-row justify="center">
              <v-col cols="12" md="9" lg="8" xl="8" class="text-center">
                <div style="height: 75vh; overflow-y: auto">
                  <div v-if="documentItem.fileType == 'application/pdf' && !documentItem.loadingImage">
                    <div v-if="displayPage">
                      <v-tabs v-model="consignmentNoteTab">
                        <v-tab>
                          Detected Page
                        </v-tab>
                        <v-tab>
                          Full Document
                        </v-tab>
                      </v-tabs>
                      <div v-if="consignmentNoteTab == 0" class="mt-1">
                        <!-- <pdf ref="pdf" :src="documentItem.fullFile" :rotate="rotate" :page="displayPage"
                          @page-loaded="displayPage = $event"></pdf> -->
                        <VuePdfEmbed :page="displayPage" :rotation="rotate" :width="fullscreen ? 1200 : 800"
                          :source="documentItem.url" />

                      </div>
                      <div v-else class="mt-1">
                        <!-- <pdf ref="pdf" :src="documentItem.fullFile" v-for="page in documentItem.pages" :key="page"
                          :rotate="rotate" :page="page"></pdf> -->
                        <VuePdfEmbed :rotation="rotate" :width="fullscreen ? 1200 : 800" :source="documentItem.url" />

                        <!-- <pdf style="display: none;" :src="documentItem.fullFile" @num-pages="pageCount = new Array($event)" />
                <pdf v-for="(page, index) in pageCount" :key="index" :src="documentItem.fullFile" :page="index + 1"
                  class="contract-container" /> -->
                      </div>


                    </div>
                    <div v-else>
                      <VuePdfEmbed :rotation="rotate" :width="fullscreen ? 1200 : 800" :source="documentItem.url" />

                      <!-- <pdf ref="pdf" :src="documentItem.fullFile" v-for="page in documentItem.pages" :key="page"
                        :rotate="rotate" :page="page"></pdf> -->
                      <!-- <pdf style="display: none;" :src="documentItem.fullFile" @num-pages="pageCount = new Array($event)" />
                <pdf v-for="(page, index) in pageCount" :key="index" :src="documentItem.fullFile" :page="index + 1"
                  class="contract-container" /> -->
                    </div>

                  </div>

                  <v-img v-else-if="!documentItem.loadingImage &&
                    documentItem.fileType &&
                    documentItem.fileType.includes('image')
                    " :style="{ transform: `rotate(${rotate}deg)` }" :src="documentItem.fullFile"></v-img>
                </div>
              </v-col>
            </v-row>
          </el-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="permitModal" :fullscreen="$vuetify.breakpoint.mobile" width="1200px">
      <v-card flat>
        <v-toolbar flat>
          <v-toolbar-title style="font-size: 16px">
            <!-- {{ documentItem.name }} -->
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn v-if="documentItem.name" icon small @click="rotate += 90">
            <v-icon>rotate_right</v-icon>
          </v-btn>
          <v-btn v-if="documentItem.name" small icon @click="rotate -= 90">
            <v-icon>rotate_left</v-icon>
          </v-btn>
          <v-btn v-if="documentItem.name" text @click="$refs.pdf[0].print()">
            <v-icon>print</v-icon>
          </v-btn>
          <v-btn icon @click="(permitModal = false), (documentItem = {})">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <el-container>
            <el-main :key="itemKey" v-loading="documentItem.loadingImage">
              <div style="height: 75vh; overflow-y: auto">
                <div v-if="documentItem.fileType == 'application/pdf'">
                  <pdf ref="pdf" :src="documentItem.file" v-for="page in documentItem.pages" :key="page" :rotate="rotate"
                    :page="page"></pdf>
                </div>

                <v-img v-else-if="documentItem.fileType &&
                  documentItem.fileType.includes('image')
                  " :style="{ transform: `rotate(${rotate}deg)` }" :src="documentItem.file"></v-img>
              </div>
            </el-main>
          </el-container>
        </v-card-text>
      </v-card>
    </v-dialog>


    <!-- Container Modal -->
    <v-dialog v-model="containerModal" width="750px" persistent :fullscreen="$vuetify.breakpoint.mobile">
      <v-card v-if="container && container.containerNo">
        <v-toolbar flat color="transparent">
          <v-toolbar-title>{{ container.ctoNo }} -
            {{ container.containerNo }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="(containerModal = false), (container = {})">X</v-btn>
        </v-toolbar>
        <v-card-text :key="containerKey" v-loading="!container.containerDetail">
          <v-list dense v-if="container.containerDetail">
            <v-list-item v-for="(event, index) in container.containerDetail.events" :key="index">
              <v-list-item-content>
                <v-list-item-title>
                  {{ event.statusDescription }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ event.statusLocation.locationName }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                {{ formatEventDate(event.statusDate.dateValue) }}
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <div v-else style="height: 200px"></div>
        </v-card-text> </v-card></v-dialog>

    <!-- Container Modal -->
    <v-dialog v-model="titanReview" width="750px" persistent :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>Titan Data Review</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="(titanReview = false), (titanItem = {})">X</v-btn>
        </v-toolbar>
        <v-card-text v-if="titanItem && titanItem.data" style="max-height: 60vh; overflow-y: auto">
          <v-list dense>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Field</v-list-item-title>
              </v-list-item-content>
              <v-list-item-content>
                <v-list-item-title>Loglive</v-list-item-title>
              </v-list-item-content>
              <v-list-item-content>
                <v-list-item-title>Titan</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item v-for="(data, index) in titanItem.data" :key="index" :style="{
              'border-bottom': '1px solid var(--v-component-base)',
              'border-left': data.value ? '3px solid green' : '3px solid red',
            }">
              <v-list-item-content>
                <v-list-item-title>{{ data.field }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-content>
                <v-list-item-title>{{ data.local }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-content>
                <v-list-item-title>{{ data.titan }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-text v-else class="text-center">
          <span style="color: grey">Pallet not inspected on Titan</span>
        </v-card-text>
      </v-card></v-dialog>

    <v-dialog v-model="palletModal" persistent width="750px" :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-card-title>
          Pallet: {{ pallet.sscc }}
          <v-spacer></v-spacer>
          <v-btn text @click="(palletModal = false), (pallet = {})">X</v-btn>
        </v-card-title>
        <v-card-text style="max-height: 65vh; overflow-y: auto" class="my-2" :key="refreshKey">
          <v-row justify="center">
            <v-col cols="12" md="6">
              <v-card v-if="pallet.consignmentNote">
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <v-list-item @click="consignmentNoteAddModal = true">
                        <v-list-item-action class="ml-0 pl-0" style="width: 20px">
                          <v-img class="mr-2" style="height: 30px; width: 30px"
                            :src="getIcon(pallet.consignmentNote.name)">
                          </v-img>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title class="text-left" style="font-size: 14px">
                            {{ pallet.consignmentNote.name }}
                          </v-list-item-title>
                          <v-list-item-subtitle style="font-size: 12px" v-if="pallet.consignmentNote.user">
                            <v-icon small>person</v-icon>
                            {{ pallet.consignmentNote.user.firstname }}
                            {{ pallet.consignmentNote.user.surname }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle style="font-size: 12px">
                            {{ formatDate(pallet.consignmentNote.createdAt) }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle style="font-size: 12px">
                            Consignment Note
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <v-card v-else style="border: 0.5px dotted grey; border-radius: 20px"
                @click="consignmentNoteAddModal = true">
                <v-card-text>
                  <v-row>
                    <v-col cols="12" class="text-center">
                      <v-icon>add</v-icon>
                    </v-col>
                    <v-col cols="12" class="text-center">
                      <h3>Consignment Note</h3>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="6">
              <v-card v-if="pallet.permitDocument">
                <v-card-text>
                  <v-list-item @click="removalPermitModal = true">
                    <v-list-item-action class="ml-0 pl-0" style="width: 20px">
                      <v-img class="mr-2" style="height: 30px; width: 30px" :src="getIcon(pallet.permitDocument.name)">
                      </v-img>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title class="text-left" style="font-size: 14px">
                        {{ pallet.permitDocument.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle style="font-size: 12px" v-if="pallet.permitDocument.user">
                        <v-icon small>person</v-icon>
                        {{ pallet.permitDocument.user.firstname }}
                        {{ pallet.permitDocument.user.surname }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle style="font-size: 12px">
                        {{ formatDate(pallet.permitDocument.createdAt) }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle style="font-size: 12px">
                        Removal Permit
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card-text>
              </v-card>
              <v-card v-else style="border: 0.5px dotted grey; border-radius: 20px" @click="removalPermitModal = true">
                <v-card-text>
                  <v-row>
                    <v-col cols="12" class="text-center">
                      <v-icon>add</v-icon>
                    </v-col>
                    <v-col cols="12" class="text-center">
                      <h3>Removal Permit</h3>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text> </v-card></v-dialog>

    <v-dialog v-model="failureModal" width="750px" :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>PPECB Addendum: Fail</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="(failureModal = false)">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-textarea rows="7" outlined placeholder="Please provide a reason for failure"></v-textarea>
          <v-row class="mt-2" justify="center">
            <v-btn text color="primary" @click="(failureModal = false)">Submit</v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="consignmentNoteAddModal" width="750px" :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>Add Consignment Note</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="(consignmentNoteAddModal = false)">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row justify="center">
            <v-col cols="12" sm="6" v-if="containerDocuments.length > 0">
              <div class="text-center">
                <v-subheader>
                  What page is this pallet on?
                </v-subheader>
                <el-input-number :min="0" size="mini" v-model="pallet.consignmentNotePage"></el-input-number>
              </div>
              <v-list>
                <v-subheader style="font-size: 16px">Container Documents</v-subheader>
                <v-list-item v-if="containerDocuments.length == 0">
                  <v-list-item-content class="text-center">
                    <span style="color: grey">No documents</span>
                  </v-list-item-content></v-list-item>
                <v-list-item v-for="document in containerDocuments" :key="document.id"
                  @click="attachDocument('Consignment Note', document)">
                  <v-list-item-action class="ml-0 pl-0" style="width: 20px">
                    <v-img class="mr-2" style="height: 30px; width: 30px" :src="getIcon(document.name)">
                    </v-img>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title class="text-left" style="font-size: 14px">
                      {{ document.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle style="font-size: 12px" v-if="document.user">
                      <v-icon small>person</v-icon>
                      {{ document.user.firstname }}
                      {{ document.user.surname }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle style="font-size: 12px" v-else>
                      System Generated
                    </v-list-item-subtitle>

                    <v-list-item-subtitle style="font-size: 12px" v-if="document.isFinancialDocument">
                      <v-icon small class="mr-1">account_balance</v-icon>
                      Financial Document
                    </v-list-item-subtitle>
                    <v-list-item-subtitle style="font-size: 12px">
                      {{ formatDate(document.createdAt) }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

              </v-list>
            </v-col>
            <v-col cols="12" sm="6" class="text-center">
              <v-btn class="mx-1" small color="blue" :loading="uploadingFile" @click="addFiles()"><v-icon
                  class="mr-2">cloud_upload</v-icon> Upload
                File</v-btn>
              <file-upload class="my-0 py-0" post-action="" :multiple="false" :drop="true" :drop-directory="true"
                v-model="fileList" ref="uploader">
              </file-upload>
            </v-col>

          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>


    <v-dialog v-model="removalPermitModal" width="750px" :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>Add Removal Permit</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="(removalPermitModal = false)">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row justify="center">
            <v-col cols="12" sm="6" v-if="containerDocuments.length > 0">
              <v-list>
                <v-subheader style="font-size: 16px">Container Documents</v-subheader>
                <v-list-item v-if="containerDocuments.length == 0">
                  <v-list-item-content class="text-center">
                    <span style="color: grey">No documents</span>
                  </v-list-item-content></v-list-item>
                <v-list-item v-for="document in containerDocuments" :key="document.id"
                  @click="attachDocument('Removal Permit', document)">
                  <v-list-item-action class="ml-0 pl-0" style="width: 20px">
                    <v-img class="mr-2" style="height: 30px; width: 30px" :src="getIcon(document.name)">
                    </v-img>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title class="text-left" style="font-size: 14px">
                      {{ document.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle style="font-size: 12px" v-if="document.user">
                      <v-icon small>person</v-icon>
                      {{ document.user.firstname }}
                      {{ document.user.surname }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle style="font-size: 12px" v-else>
                      System Generated
                    </v-list-item-subtitle>

                    <v-list-item-subtitle style="font-size: 12px" v-if="document.isFinancialDocument">
                      <v-icon small class="mr-1">account_balance</v-icon>
                      Financial Document
                    </v-list-item-subtitle>
                    <v-list-item-subtitle style="font-size: 12px">
                      {{ formatDate(document.createdAt) }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

              </v-list>
            </v-col>
            <v-col cols="12" sm="6" class="text-center">
              <v-btn class="mx-1" small color="blue" :loading="uploadingFile" @click="addPermitFiles()"><v-icon
                  class="mr-2">cloud_upload</v-icon> Upload
                File</v-btn>
              <file-upload class="my-0 py-0" post-action="" :multiple="false" :drop="true" :drop-directory="true"
                v-model="removalPermitList" ref="permitUploader">
              </file-upload>
            </v-col>

          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>
<script>
import axios from "axios";
import dateFormat from "dateformat";
import FileUpload from "vue-upload-component";

import pdf from "vue-pdf";
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'

export default {
  props: ["item"],
  components: { FileUpload, pdf, VuePdfEmbed },
  watch: {
    'item.id': {
      immediate: true,
      async handler(val) {
        if (val) {
          this.containerDocuments = await this.$API.getContainerDocuments(
            this.item.id
          );
        }
      }
    },
    fileList: {
      immediate: true,
      handler(val) {
        if (val.length > 0) {
          this.submitFiles();
        }
      },
    },
    removalPermitList: {
      immediate: true,
      handler(val) {
        if (val.length > 0) {
          this.submitPermitFiles();
        }
      },
    },
  },
  data: () => ({
    auditLines: [],
    consignmentNoteFullscreen: true,
    container: {},
    containerDocuments: [],
    containerModal: false,
    containerKey: 0,
    consignmentNoteModal: false,
    consignmentNoteAddModal: false,
    documentItem: {},
    documents: [],
    documentKey: 1000,
    fileList: [],
    fullscreen: false,
    itemKey: 0,
    breakBulk: null,
    displayPage: null,
    consignmentNoteTab: 0,
    headers: [
      {
        text: "Action",
        value: "action",
        sortable: false,
        width: "5px",
        align: "center",
      },
      {
        text: "Line",
        value: "line",
        sortable: false,
        width: "5px",
        align: "center",
      },
      {
        text: "Additional",
        value: "additional",
        sortable: false,
        width: "280px",
        align: "center",
      },
      {
        text: "Producer/ PUC(s)",
        value: "farm",
        sortable: false,
        align: "center",
      },
      {
        text: "Orchard",
        value: "orchard",
        sortable: false,
        align: "center",
      },
      {
        text: "Phyto Data",
        value: "phytoReference",
        sortable: false,
        align: "center",
      },
      {
        text: "PHC",
        value: "packHouse",
        sortable: false,
        align: "center",
      },
      {
        text: "Production Area",
        value: "productionArea",
        sortable: false,
        align: "center",
      },
      {
        text: "Commodity",
        value: "commodity",
        sortable: false,
        align: "center",
      },
      {
        text: "Variety",
        value: "variety",
        sortable: false,
        align: "center",
      },
      {
        text: "Flesh Colour",
        value: "fleshColour",
        sortable: false,
        align: "center",
      },
      {
        text: "Class/ Grade",
        value: "gradeClassCode",
        width: "20px",

        sortable: false,
        align: "center",
      },
      {
        text: "Count",
        value: "countSize",
        width: "20px",
        sortable: false,
        align: "center",
      },
      {
        text: "Pack Type",
        value: "packType",
        sortable: false,
        align: "center",
      },
      {
        text: "Quantity",
        value: "noCartons",
        sortable: false,
        width: "10px",
        align: "right",
      },
      {
        text: "Nett Weight",
        value: "nettWeight",
        sortable: false,
        align: "right",
      },
      {
        text: "UPN No.",
        value: "upn",
        sortable: false,
        align: "center",
      },
      {
        text: "Pallet ID",
        value: "sscc",
        sortable: false,
        align: "center",
      },
      {
        text: "PPECB Inspection Date",
        value: "inspectionDate",
        width: "100px",
        sortable: false,
        align: "center",
      },
      {
        text: "Stuff Date",
        value: "stuffDate",
        width: "100px",
        sortable: false,
        align: "center",
      },
      {
        text: "Manifest No.",
        value: "inspectionManifestNo",
        sortable: false,
        align: "center",
      },

      {
        text: "Inspection Point",
        value: "inspectionPoint",
        sortable: false,
        align: "center",
      },
    ],
    failureModal: false,
    modal: false,
    loadingField: null,
    loadingKey: 0,
    pallet: {},
    palletModal: false,
    permitModal: false,
    phytCleanVerification: [],
    phytcleanVerificationType: "allPallets",
    phytoUpdateKey: 0,
    propertyKey: 0,
    refreshKey: 2000,
    removalPermitModal: false,
    removalPermitList: [],
    rotate: 0,
    savingPallet: false,
    selectedElementType: null,
    selectedElement: null,
    submitPhytcleanModal: false,
    titanVerification: [],
    titanReview: false,
    titanItem: {},
    updateKey: 0,
    uploadingFile: false,
  }),
  mounted() {
    this.breakBulk = this.item.shipment ? this.item.shipment.breakBulkShipment:false
  },
  computed: {
    containerFarms() {
      return [...new Set(this.item.containerPallets.map((x) => x.farm))];
    },
    numberOfPallets() {
      return [...new Set(this.item.containerPallets.map((x) => x.sscc))].length;
    },
    sumCartons() {
      return this.item.containerPallets
        .map((x) => parseInt(x.noCartons))
        .reduce((a, b) => a + b, 0);
    },
    sumNettWeight() {
      return this.item.containerPallets
        .map((x) => parseFloat(x.nettWeight))
        .reduce((a, b) => a + b, 0);
    },
    sumGrossWeight() {
      return this.item.containerPallets
        .map((x) => parseFloat(x.grossWeight))
        .reduce((a, b) => a + b, 0);
    },
    uniquePallets() {
      return [...new Set(this.item.containerPallets.map(x => x.sscc))];
    },
    
  },
  async created() {
    // this.getPPECBAuditLines();
    this.$socket.onmessage = (data) => {
      let message = JSON.parse(data.data);
      if (
        message.type == "phytclean_complete" &&
        message.containerId == this.item.id
      ) {
        this.getPhytCleanData(this.item.id);
      }
    };
  },
  methods: {
    addFiles() {
      this.$refs.uploader.$el.children[0].click();
    },
    addPermitFiles() {
      this.$refs.permitUploader.$el.children[0].click();
    },
    async attachDocument(type, document) {
      if (type == 'Removal Permit') {
        this.pallet.permitDocument = document;
        this.pallet.permitDocumentId = document.id;
        await this.$API.updatePallet({
          id: this.pallet.id,
          permitDocumentId: document.shipmentId
        })
        this.removalPermitModal = false
        this.refreshKey++
      } else if (type == 'Consignment Note') {
        this.pallet.consignmentNote = document;
        this.pallet.consignmentNoteId = document.id;
        await this.$API.updatePallet({
          id: this.pallet.id,
          consignmentNoteId: document.id,
          consignmentNotePage: this.pallet.consignmentNotePage
        })
        this.consignmentNoteAddModal = false
        this.refreshKey++
      }
    },
    checkField(field, item) {
      let result = false;
      if (
        item.containerPalletAddendum &&
        item.containerPalletAddendum[field] &&
        item.containerPalletAddendum[field] != item[field]
      ) {
        result = true;
      }
      return result;
    },
    async getPPECBAuditLines() {
      let auditLines = await this.$API.getPPECBAuditLines();
      this.auditLines = JSON.parse(auditLines.lines);
    },
    setEditElement(type, id) {
      this.selectedElementType = type;
      this.selectedElement = id;
      this.updateKey++;
    },
    checkItemValue(item, itemHeader, titanHeader) {
      let result = false;
      let find = this.titanVerification.find(
        (x) => x.sscc == item.sscc && x.puc == item.farm
      );
      if (find) {
        result = item[itemHeader] == find[titanHeader];
      }
      return result;
    },
    checkPhytoVerification(item) {
      let find = this.phytCleanVerification.find(
        (x) => x.containerPalletId == item.id
      );
      let result = false;
      if (find) {
        result =
          item.phytoReference != "" &&
          item.phytoReference &&
          find.phytoReference == item.phytoReference;
      }
      return result;
    },
    checkItemProcessed(item) {
      let find = this.phytCleanVerification.find(
        (x) => x.containerPalletId == item.id
      );
      let result = false;
      if (find) {
        result = find.processed;
      }
      return result;
    },
    checkPPECB() {
      let keys = ['portOfLoad', 'finalDestinationCode', 'targetRegion', 'portOfDischargeValue', 'mainCarriageVesselName', 'mainCarriageConveyanceNumber', 'etd', 'regimeCode', 'noCartons', 'nettWeight', 'noPallets']
      let result = true
      for (let i = 0; i < keys.length; i++) {
        if (!this.item.ppecb[keys[i]]) {
          result = false
          break
        }
      }
      console.log('PPECB check', result)
      return result
    },
    confirmPhytoItem(item) {
      let find = this.phytCleanVerification.find(
        (x) => x.containerPalletId == item.id
      );
      return find ? true : false;
    },
    async changePropertyValue(property) {
      if (this.item.ppecb[property] == false) {
        this.item.ppecb[property] = null;
      } else if (this.item.ppecb[property] == null) {
        this.item.ppecb[property] = true;
      } else {
        this.item.ppecb[property] = false;
      }
      this.propertyKey++;
      await this.$API.updatePPECBContainer({ id: this.item.id, ppecb: this.item.ppecb })
    },
    checkProductionArea(item) {
      let find = this.phytCleanVerification.find(
        (x) => x.containerPalletId == item.id
      );
      let result = false;
      if (find) {
        result =
          item.productionArea != "" &&
          item.productionArea &&
          find.productionArea == item.productionArea;
      }
      return result;
    },
    editPallet(pallet) {
      this.pallet = pallet;
      this.palletModal = true;
    },
    finalDestinationCode(code) {
      return code ? code.substring(0, 2) : null;
    },

    formatEventDate(date) {
      let year = date.substring(0, 4);
      let month = parseInt(date.substring(4, 6)) - 1;
      let day = date.substring(6, 8);
      let hour = date.substring(8, 10);
      let minute = date.substring(10, 12);
      let setDate = new Date(year, month, day, hour, minute);
      return dateFormat(setDate, "dd mmm, yyyy HH:MM");
    },
    getProductionArea(item) {
      let find = this.phytCleanVerification.find(
        (x) => x.containerPalletId == item.id
      );
      return find ? find.productionArea : null;
    },
    getCheckColour(property) {
      let result = "orange";
      if (property == false) {
        result = "red";
      } else if (property) {
        result = "green";
      }
      return result;
    },
    getCheckIcon(property) {
      let result = "warning";
      if (property == false) {
        result = "cancel";
      } else if (property) {
        result = "check_circle";
      }
      return result;
    },
    getIcon(name) {
      let type = name.split(".");
      let result = null;
      if (type.length > 0) {
        result = type.at(-1).toLowerCase();
      }
      if (result && result.toLowerCase() == "xlsx") {
        result = "xls";
      }
      return `/icons/${result}.svg`;
    },
    formatDate(date) {
      let result = null;
      if (date) {
        result = dateFormat(date, "dd/mm/yyyy");
      }
      return result;
    },
    formatNumber(number) {
      return number.toLocaleString(undefined, { minimumFractionDigits: 0 });
    },
    isTitanReady() {
      let pallets = this.item.containerPallets.map((x) => x.sscc);
      let result = pallets.every((x) =>
        this.titanVerification.map((y) => y.sscc).includes(x)
      );
      return result;
    },
    getRowClass(item) {
      let index = this.item.containerPallets.indexOf(item);
      if (
        this.auditLines.includes(index + 1) &&
        !this.checkPhytoVerification(item)
      ) {
        return "auditLine";
      }
    },
    async getPhytCleanData(id) {
      this.phytCleanVerification =
        await this.$API.getPhytCleanContainerVerification(id);
      this.phytoUpdateKey++;
    },
    async getTitanData(id) {
      this.titanVerification = await this.$API.getTitanPallets(id);
    },
    async savePallet() {
      this.savingPallet = true;
      await this.$API.updatePallet(this.pallet);
      this.$message.success("Successfully updated!");
      this.palletModal = false;
      this.pallet = {};
      this.savingPallet = false;
    },
    titanCheck(item) {
      let result = false;
      let find = this.titanVerification.find(
        (x) => x.sscc == item.sscc && x.sequenceNo == item.sequenceNumber
      );
      if (find) {
        result = true;
        result =
          find.commodity == item.commodity &&
          find.variety == item.variety &&
          find.consignmentNumber == item.inspectionManifestNo &&
          find.puc == item.farm &&
          find.phc == item.packHouse &&
          find.gradeClassCode == item.gradeClassCode &&
          find.noCartons == item.noCartons &&
          find.phytoData == item.phytoReference;
      }
      return result;
    },
    async trackContainer(container) {
      this.container = container;
      this.containerModal = true;
      this.container.containerDetail = await this.$API.trackContainer({
        params: {
          containerNumber: container.containerNo,
        },
      });
      this.container.containerDetail = this.container.containerDetail[0];
      this.containerKey++;
    },
    async updatePallet(type, field, item) {
      this.loadingField = [type, field, item.id].join("_");
      this.loadingKey++;
      await this.$API.updatePallet({
        id: item.id,
        [field]: item.containerPalletAddendum[field],
      });
      this.loadingField = null;
      item[field] = item.containerPalletAddendum[field];
      this.loadingKey++;
    },
    async downloadDocument(doc) {
      var hiddenElement = document.createElement("a");
      hiddenElement.href = doc.file;
      hiddenElement.target = "_blank";
      hiddenElement.download = doc.name;
      hiddenElement.click();
    },
    async viewPhytoCertificate(item) {
      let meta = item.palletMetadata.find((x) => x.key == "Phytclean Verified");
      // let find = this.phytCleanVerification.find(
      //   (x) => x.containerPalletId == item.id && x.certificate
      // );
      this.documentItem = item;
      this.documentItem.loadingImage = true;
      this.modal = true;
      let file = await this.$API.downloadFile({
        key: meta.data,
      });
      this.documentItem.name = item.sscc;
      this.documentItem.file = file.file;
      this.documentItem.fileType = file.fileType;
      this.documentItem.loadingImage = false;
      this.itemKey++;
    },
    async viewConsignmentNote(item) {
      this.documentItem = item.consignmentNote;
      this.documentItem.pallet = { ...item }
      delete this.documentItem.pallet.consignmentNote
      console.log('Item', this.documentItem)
      this.documentItem.sscc = item.sscc;
      this.documentItem.loadingImage = true;
      this.consignmentNoteModal = true;
      this.documentItem.url = null
      console.log('Key', item.consignmentNote.key)
      this.documentItem.url = await this.$API.getDocumentLink({
        id: item.consignmentNote.id,
        key: item.consignmentNote.key
      })
      this.documentItem.fileType = this.documentItem.type;
      this.displayPage = item.consignmentNotePage ?? null
      console.log('document type', this.documentItem.fileType)
      console.log('URL', this.documentItem.url)
      // let file = await this.$API.downloadFile({
      //   key: item.consignmentNote.key,
      // });
      this.documentItem.name = item.consignmentNote.name;
      // this.documentItem.file = file.file;
      // this.documentItem.fileType = file.fileType;
      this.documentItem.loadingImage = false;
      this.itemKey++;
    },
    toDataUrl(url, callback) {
      var xhr = new XMLHttpRequest();
      xhr.onload = function () {
        var reader = new FileReader();
        reader.onloadend = function () {
          callback(reader.result);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.send();
    },
    async submitFiles() {
      this.uploadingFile = true

      let pending = this.consignmentNoteAddModal ? this.fileList : this.removalPermitList;
      console.log(this.consignmentNoteAddModal, pending, this.removalPermitList)
      for (let i = 0; i < pending.length; i++) {
        let url = await URL.createObjectURL(pending[i].file);
        this.toDataUrl(url, (data) => {
          let obj = {
            name: pending[i].name,
            data: data,
            type: pending[i].type,
            fileIndex: i,
            shipmentId: this.$route.params.shipmentId,
          };
          this.submitUpload(obj);
        });
      }
    },
    async submitPermitFiles() {
      this.uploadingFile = true
      let pending = this.removalPermitList;
      for (let i = 0; i < pending.length; i++) {
        let url = await URL.createObjectURL(pending[i].file);
        this.toDataUrl(url, (data) => {
          let obj = {
            name: pending[i].name,
            data: data,
            type: pending[i].type,
            fileIndex: i,
            shipmentId: this.$route.params.shipmentId,
          };
          this.submitUpload(obj);
        });
      }
    },
    itemRowBackground(item) {
      if (item.audit) {
        return 'myclass'
      }
    },
    async submitUpload(obj) {
      let Api = axios.create({
        // baseURL: "http://localhost:3000",
        baseURL: process.env.NODE_ENV == 'production' ? 'https://api.loglive.ai' : process.env.NODE_ENV == 'staging' ? 'https://api.loglive.ai': 'http://localhost:3000',
        headers: {
          Authorization: `${this.$store.state.token}`,
          apikey: `${this.$store.state.orgToken}`,
        },
      });
      let result = await Api.post("/upload/document", obj);
      if (this.consignmentNoteAddModal) {
        console.log('Consignment note')
        this.pallet.consignmentNoteId = result.data.id;
        this.pallet.consignmentNote = result.data;
        await this.$API.updatePallet({
          id: this.pallet.id,
          consignmentNoteId: this.pallet.consignmentNoteId,
          consignmentNotePage: this.pallet.consignmentNotePage
        })
      } else if (this.removalPermitModal) {
        console.log('Removal Permit')
        this.pallet.permitDocumentId = result.data.id;
        this.pallet.permitDocument = result.data;
        await this.$API.updatePallet({
          id: this.pallet.id,
          permitDocumentId: this.pallet.permitDocumentId,
        })
      }

      this.fileList = [];
      this.removalPermitList = []
      this.documentKey++;
      this.consignmentNoteAddModal = false
      this.removalPermitModal = false
      this.uploadingFile = false
      this.refreshKey++
    },
    async viewRemovalPermit(item) {
      this.documentItem = item.permitDocument;
      this.documentItem.loadingImage = true;
      this.permitModal = true;
      let file = await this.$API.downloadFile({
        key: item.permitDocument.key,
      });
      this.documentItem.name = item.sscc;
      this.documentItem.file = file.file;
      this.documentItem.fileType = file.fileType;
      this.documentItem.loadingImage = false;
      this.itemKey++;
    },
    viewTitan(item) {
      let find = item.palletMetadata.find((x) => x.key == "Titan Ready");
      this.titanItem = find;
      this.titanReview = true;
    },
    metadataValue(type, metadata) {
      let find = metadata.find((x) => x.key == type);
      return find.value;
    },
    checkMetadata(type, metadata) {
      let find = metadata.find((x) => x.key == type);
      return find ? true : false;
    },
    verify(type, metadata) {
      let find = metadata.find((x) => x.key == type);
      return find ? find.value == "Passed" : false;
    },
  },
};
</script>

<style scoped>
input.middle:focus {
  outline-width: 0;
}

.el-input__inner {
  padding: 0px;
  margin: 0px;
}

.myclass {
  color: orange !important;
  /* background-color: orange !important; */
  /* color: orange !important; */

}
</style>
